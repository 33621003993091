
import { parseTime } from "@/utils/index.js";
import {
  fundWatchProjectDoStatusDic,
  fundWatchSourceDic,
} from "@/network/lookUp.ts";
import roles from "@/types/roles.ts";
import { ElMessageBox } from "element-plus";
import { useCityRelTown } from "@/network/chinaArea";
import Pager from "@/views/layout/pager.vue";
import StatusFinish from "./statusFinish.vue";
import StatusSubmit from "./statusSubmit.vue";
import DispatchUser from "./dispatchUser.vue";
import Base from "@/views/layout/Base.vue";
import { useQueryProject, useFundWatchAction } from "@/network/fundWatch";
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
  toRef,
  inject,
} from "vue";
import { openWindowWithToken, openPostWindow } from "@/utils/util";
export default defineComponent({
  components: {
    Base,
    StatusFinish,
    StatusSubmit,
    Pager,
    DispatchUser,
  },
  setup() {
    const user: any = inject("user");
    const statusFinishSetting = reactive({
      visible: false,
      id: null,
      title: null,
      data: {},
    });

    const dispatchUserSetting = reactive({ visible: false, item: null });
    const formAction = ref();
    const setting = reactive({ visible: false, id: null, data: {}, active_Name: "project" });
    const showEdit = (item) => {
      formAction.value = "edit";
      setting.visible = true;
      setting.id = item.id;
      // setting.data = item;
    };

    const showView = (item) => {
      formAction.value = "view";
      setting.id = item.id;
      console.log(setting.id);
      setting.visible = true;
      // // setting.data = item;
    };

    const [isProcessing, , , projectDispatch] = useFundWatchAction();
    const yearDic = [];

    let startYear = new Date().getFullYear() + 1;
    for (let i = 0; i <= 10; i++) {
      yearDic.push(startYear--);
    }

    const filter = reactive({
      primary: null,
      isMemo: null,
      year: null,
      source: "市级财政",
      keyword: null,
      city: null,
      town: null,
      pageNum: 1,
      pageSize: 20,
      status: null,
      fundYearStart: null,
      fundYearEnd: null,
      selFill: false,
      sortBy: null,
      sortByDirection: null,
      isBelongProv: false,
      hasDispatch: null,
      hasFinishCheck: null,
    });

    if (user.inRoles(roles.fundCountyManager)) {
      filter.town = user.county_ID;
    } else if (user.inRoles(roles.city)) {
      filter.city = user.city_ID;
    }

    const [dicCity, dicTown] = useCityRelTown(
      toRef(filter, "city"),
      toRef(filter, "town")
    );
    const [isLoading, pagedList, query, pager] = useQueryProject(filter);

    const onSelected = (id) => {
      projectDispatch(dispatchUserSetting.item.id, id).then(() => {
        query();
      });
    };
    onMounted(() => {
      query();
    });

    const exportExcel = () => {
      openPostWindow(
        `${process.env.VUE_APP_BASE_API}/FundWatch/export/queryProject`,
        filter
      );
    };

    const dispatchShow = (item) => {
      dispatchUserSetting.visible = true;
      dispatchUserSetting.item = item;
    };

    const showCheckEdit = (item) => {
      statusFinishSetting.visible = true;
      statusFinishSetting.title = item.project_Name;
      statusFinishSetting.id = item.id;
      statusFinishSetting.data = item;
    };

    const showMemoEdit = (item) => {
      ElMessageBox.prompt("请填写备注内容", "备注", {
        inputValue: item.memo,
        type: "warning",
        customClass: "backConfirmForm",
        confirmButtonText: "保存",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputPattern: /(.+){5,}/,
        inputErrorMessage: "内容不得少于5个字！",
        inputPlaceholder: "请输入备注内容",
      }).then(({ value }) => {
        item.memo = value;

        //run(id, { forceFlag: 1, memo: value });
      });
    };

    return {
      formAction,
      statusFinishSetting,
      isProcessing,
      dispatchUserSetting,
      dispatchShow,
      onSelected,
      showView,

      exportExcel,
      fundWatchProjectDoStatusDic,
      fundWatchSourceDic,
      yearDic,
      dicCity,
      dicTown,
      filter,
      setting,
      showEdit,
      isLoading,
      pagedList,
      query,
      pager,
      user,
      roles,
      showMemoEdit,
      showCheckEdit,
      parseTime,
    };
  },
});
